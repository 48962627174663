export const TAGS_TYPES = ["Info", "Danger", "Restricted"]

export const CAMERA_PINS_TYPES = {
  default: "Default",
}

export const MESSAGES_TYPES = {
  liveView: "liveView",
  editSnapshot: "editSnapshot",
  analyticsEvent: "analyticsEvent",
  history: "history",
  shareLink: "shareLink",
}

export const WIDGETS = {
  pinsList: "pinsList",
  editPins: "editPins",
  markersList: "markersList",
  tagsList: "tagsList",
}

export const EVENT_SENDERS = {
  _360Viewer: "360 viewer",
  modelViewer: "model viewer",
}

export const TOUR_SPEEDS = [
  {
    name: "x0.5",
    value: 5000,
  },
  {
    name: "x1",
    value: 2500,
  },
  {
    name: "x2",
    value: 1000,
  },
  {
    name: "x4",
    value: 500,
  },
]
export const TOUR_SPEED = {
  X0_5: "x0.5",
  X1: "x1",
  X2: "x2",
  X4: "x4",
}

export const TUTORIALS = [
  {
    name: "Edit Tool",
    path: "edit-tool",
  },
  {
    name: "Compare",
    path: "compare",
  },
  {
    name: "Floor Markers",
    path: "floor-markers",
  },
  {
    name: "Date Selection",
    path: "date-selection",
  },
  {
    name: "Floor Selection",
    path: "floor-picker",
  },
  {
    name: "Play Tour",
    path: "play-tour",
  },
  {
    name: "Tagging",
    path: "tagging",
  },
  {
    name: "Measuring",
    path: "measuring",
  },
  {
    name: "Mini Model",
    path: "mini-model",
  },
  {
    name: "Share Link",
    path: "share-link",
  },
  {
    name: "Mini Map",
    path: "mini-map",
  },
]

export default {}

// Calibration constants
export const CALIBRATION = {
  COPLANAR_THRESHOLD: 0.0001,
  SPHERE_SEGMENTS: 16,
  POINT_OFFSET: 0.05,
  RED_COLOR: 0xff0000,
}

// Forge viewer mouse events
export const FORGE_VIEWER_MOUSE_EVENTS = [
  "mousewheel",
  "DOMMouseScroll",
  "wheel",
  "touchstart",
  "pointerdown",
  "gesturestart",
]

// Forge viewer movement keys events
export const FORGE_VIEWER_MOVEMENT_KEYS = [
  "ArrowUp",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "W",
  "A",
  "S",
  "D",
  "E",
  "F",
  "Q",
  "w",
  "a",
  "s",
  "d",
  "e",
  "f",
  "q",
]
